import { Input } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import './styles.scss';
import Box from '@mui/material/Box';
import Cube from 'assets/images/cube.svg';
import Right from 'assets/images/chevron_right.svg';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

type LocationInputType = {
  location: string;
  onClick: () => void;
}

const LocationInput = ({ location, onClick }: LocationInputType) => {
  const navigate = useNavigate();

  return (
    <>
      <Input disableUnderline
             readOnly
             className="input-location"
             value={location}
             endAdornment={<SearchIcon />}
             onClick={onClick}
      />
      <Box component="button"
           className="download-box"
           onClick={() => navigate('/introduces')}
           boxShadow={'0px 4px 10px 0px #8A626233'}>
        <Box className="download-box-text">
          <Box component="img"
               src={Cube} />
          <Typography fontFamily="KBODiaGothic"
                      fontSize="14px"
                      fontWeight={500}
                      color="#000000">1분 내로 가입하고</Typography>
          <Typography fontFamily="KBODiaGothic"
                      fontSize="14px"
                      fontWeight={700}
                      color="#FF6151">&nbsp;리워드 설탕&nbsp;</Typography>
          <Typography fontFamily="KBODiaGothic"
                      fontSize="14px"
                      fontWeight={500}
                      color="#000000">받아가세요</Typography>
        </Box>
        <Box component="img"
             src={Right} />
      </Box>
    </>
  );
};

export default LocationInput;
