import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary, Drawer, Stack, Tab, Tabs } from '@mui/material';

import { useAppSelector } from '../../../app/hooks';
import { setLoading } from '../../../components/Loading/loadingSlice';
import { FetchPopupStore } from '../../../services/popup-stores/fetchPopupStore';
import { PopupStore, PopupStoreOpenTime } from '../../../services/presentation/PopupStore';
import Box from '@mui/material/Box';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import OrangeLocation from 'assets/icons/location_orange.svg';
import CheckboxIcon from 'assets/icons/checkbox.svg';
import GiftBoxIcon from 'assets/icons/gift_box.svg';
import Button from '@mui/material/Button';
import dayjs, { Dayjs } from 'dayjs';
import "dayjs/locale/ko";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ParkingIcon from 'assets/icons/parking.svg';
import FreeEntranceIcon from 'assets/icons/free.svg';
import WatchIcon from 'assets/icons/watch.svg';
import OfficialIcon from 'assets/icons/official_icon.svg';
import InstagramIcon from 'assets/icons/instagram.svg';
import SugarIcon from 'assets/images/ic_sugar.svg';
import ShareIcon from 'assets/icons/share_icon.svg';
import Appdownload from 'assets/icons/app_download.svg';
import ChevronRightWhite from 'assets/icons/chevronright_white.svg';
import { FetchPopupStorePosts } from '../../../services/popup-stores/fetchPopupStorePosts';
import { PopupStorePost } from '../../../services/presentation/PopupStorePost';
import "./styles.scss";
import { calculateTimeAgo } from '../../../utils/formartTime';
import Avatar from '@mui/material/Avatar';
import DrawerRoot from '../../../components/Drawer/Drawer';
import BackButton from '../../../components/BackButton/BackButton';
dayjs.locale("ko");

const usePopupStoresDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useAppSelector((store) => store.loading);
  const [popupStore, setPopupStore] = useState<PopupStore | null>(null);
  const [carouselItems, setCarouselItems] = useState<JSX.Element[]>([]);
  const [selectedItem, setSelectedItem] = useState<number>(0);
  const [todayOpening, setTodayOpening] = useState<PopupStoreOpenTime>({
    isOpen: false,
    opening: "",
    closing: "",
  });
  const [today, setToday] = useState<number>(-1);
  const [opening, setOpening] = useState<Dayjs | null>(null);
  const [closing, setClosing] = useState<Dayjs | null>(null);
  const [tabValue, setTabValue] = useState<number>(0);
  const [posts, setPosts] = useState<PopupStorePost[]>([]);
  const [bottomSheetOpen, setBottomSheetOpen] = useState(false);

  const onHandleTab = (e: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    dispatch(setLoading(true));

    async function fetchPopupStoreDetail() {
      const fetchedPopupStore = await FetchPopupStore(Number(id));
      if (fetchedPopupStore) {
        setPopupStore(fetchedPopupStore);
        dispatch(setLoading(false));
      }
    }

    fetchPopupStoreDetail();
  }, [id, dispatch]);

  useEffect(() => {
    if (!loading || !popupStore) {
      return;
    }

    async function fetchPopupStorePosts() {
      const fetchedPosts = await FetchPopupStorePosts(popupStore!.id);
      setPosts(fetchedPosts);
    }

    fetchPopupStorePosts();
    const items = popupStore.thumbnailImages.map((image, index) => (
      <Box key={index}
           component="img"
           src={image}
           width="100%"
           height="414px"
      />
    ));

    const nowDayjs: dayjs.Dayjs = dayjs();
    let dayString = "";
    switch (nowDayjs.get('day')) {
      case 0:
        dayString = "sunday";
        break;
      case 1:
        dayString = "monday";
        break;
      case 2:
        dayString = "tuesday";
        break;
      case 3:
        dayString = "wednesday";
        break;
      case 4:
        dayString = "thursday";
        break;
      case 5:
        dayString = "friday";
        break;
      case 6:
        dayString = "saturday";
        break;
    }

    setCarouselItems(items);
    setSelectedItem(0);
    // @ts-ignore
    const todayOpening = popupStore.openings[dayString];
    setTodayOpening(todayOpening);
    if (!todayOpening.isOpen) {
      return;
    }
    setToday(nowDayjs.get('day'));
    setOpening(dayjs().set('hour', todayOpening.opening.split(":")[0]).set('minute', todayOpening.opening.split(":")[1]));
    setClosing(dayjs().set('hour', todayOpening.closing.split(":")[0]).set('minute', todayOpening.closing.split(":")[1]));
  }, [id, loading, popupStore]);

  const onClickPost = useCallback((index: number) => {
    navigate(`/posts/${index}`);
  }, [navigate]);

  return {
    models: {
      loading,
      popupStore,
      carouselItems,
      selectedItem,
      todayOpening,
      opening,
      closing,
      tabValue,
      today,
      posts,
      bottomSheetOpen,
    },
    operations: {
      onHandleTab,
      onClickPost,
      setBottomSheetOpen,
      navigate,
    },
  };
}

export default function PopupStoresDetail() {
  const { models, operations } = usePopupStoresDetail();

  return  models.loading.loading || !models.popupStore ? (<></>) : (
    <div id="drawer-container"
         style={{
            position: 'relative',
         }}>
      <Grid container
            justifyContent="space-between"
            sx={{
              position: 'absolute',
              top: '0',
              padding: '20px 10px 10px 10px',
              zIndex: 100,
            }}>
        <Grid item>
          <Grid container
                justifyContent="center"
                alignItems="center"
                height="100%">
            <BackButton onClick={() => operations.navigate(-1)}
                        isWhite={true} />

          </Grid>
        </Grid>
      </Grid>
      <Stack sx={{
        backgroundColor: '#fff !important',
        height: '100svh !important',
        overflow: 'auto',
      }}>
        <Grid container
              padding={0}>
          <Carousel autoPlay={true}
                    infiniteLoop={true}
                    showArrows={false}
          >
            {models.carouselItems}
          </Carousel>
        </Grid>

        <Stack paddingLeft="10px"
               paddingRight="10px"
               mt={-4}>
          <Grid container
                display="flex"
                flexDirection="row">
            <Typography fontFamily="Apple SD Gothic Neo"
                        fontSize="12px"
                        lineHeight="14px"
                        fontWeight={700}
                        mt={1}
                        color="#FF6151">🔥{models.popupStore.viewCount.toLocaleString('en')}명</Typography>
            <Typography fontFamily="Apple SD Gothic Neo"
                        fontSize="12px"
                        lineHeight="14px"
                        mt={1}
                        color="#969696">이 봤어요</Typography>
          </Grid>

          <Grid container
                mt={1}>
            <Typography fontFamily="Apple SD Gothic Neo"
                        fontSize="20px"
                        lineHeight="24px"
                        fontWeight={700}
                        color="#000000">{models.popupStore.name}</Typography>
          </Grid>

          <Grid container
                mt={1}
                display="flex"
                alignItems="center">
            <Box component="img"
                 src={OrangeLocation} />&nbsp;
            <Typography fontFamily="Apple SD Gothic Neo"
                        fontSize="12px"
                        lineHeight="14px"
                        fontWeight={400}
                        color="#969696"
                        sx={{ textDecoration: 'underline' }}>{models.popupStore.address}</Typography>
          </Grid>

          <Grid container
                spacing={2}
                mt={.5}>
            <Grid item
                  xs={9}>
              <Box display="flex"
                   position="relative"
                   height="50px">
                <Box position="absolute"
                     display="flex"
                     flexDirection="column"
                     alignItems="center"
                     justifyContent="center"
                     width="79px"
                     height="50px"
                     borderRadius="8px"
                     rowGap="2px"
                     zIndex={1}
                     paddingTop="4px"
                     sx={{ backgroundColor: '#FF6151' }}>
                  <Typography fontFamily="Apple SD Gothic Neo"
                              fontSize="12px"
                              lineHeight="12px"
                              fontWeight={700}
                              color="#F8F8F8">종료까지</Typography>
                  <Typography fontFamily="Sansation"
                              fontSize="18px"
                              lineHeight="25.2px"
                              fontWeight={700}
                              color="#FFFFFF">D-{dayjs(models.popupStore.endDate).diff(dayjs(), 'day')}</Typography>
                </Box>
                <Box position="absolute"
                     left="50px"
                     width="calc(100% - 50px)"
                     paddingLeft="35px"
                     height="50px"
                     display="flex"
                     flexDirection="column"
                     alignItems="start"
                     rowGap="1px"
                     paddingTop="1px"
                     borderRadius="8px"
                     sx={{ backgroundColor: "#EEEEEE" }}>
                  <Typography fontFamily="Sansation"
                              fontSize="16px"
                              fontWeight={700}
                              color="#FF6151">
                    {dayjs(models.popupStore.startDate).format("MM.DD")} ~ {dayjs(models.popupStore.endDate).format("MM.DD")}
                  </Typography>
                  <Typography fontFamily="Sansation"
                              fontSize="12px"
                              fontWeight={400}
                              color="#969696">
                    {models.todayOpening.isOpen ? `${models.opening?.locale('en').format("HH:mm A")} ~ ${models.closing?.locale('en').format("hh:mm A")}` : "휴무"}
                  </Typography>

                </Box>
              </Box>
            </Grid>
            <Grid item
                  xs={3}
            >
              <Button onClick={() => operations.navigate('/introduces')}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "50px",
                        backgroundColor: "#303030",
                        borderRadius: "8px",

                        "&:hover": {
                          backgroundColor: "#303030",
                        },
                      }}>
                <Box component="img" src={CheckboxIcon} />&nbsp;
                <Typography fontFamily="Apple SD Gothic Neo"
                            fontSize="14px"
                            lineHeight="16.8px"
                            fontWeight={700}
                            color="#F8F8F8">예약하기</Typography>
              </Button>
            </Grid>
          </Grid>
        </Stack>

        {models.popupStore.eventsTitle && models.popupStore.events && (
          <Accordion square
                     sx={{
                       marginTop: "10px",
                       border: 'none',
                       boxShadow: 'none',
                     }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel2-content"
                              id="panel2-header">
              <Box display="flex"
                   alignItems="center">
                <Box component="img"
                     src={GiftBoxIcon} /> &nbsp;
                <Typography fontFamily="Apple SD Gothic Neo"
                            fontSize="16px"
                            lineHeight="19.2px"
                            fontWeight={700}
                            color="#000000"
                            sx={{ marginTop: "3px" }}>{models.popupStore.eventsTitle}</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography whiteSpace="pre-wrap"
                          fontFamily="Apple SD Gothic Neo"
                          fontSize="14px">
                {models.popupStore.events}
              </Typography>
            </AccordionDetails>
          </Accordion>
        )}

        <Stack>
          <Box>
            <Tabs value={models.tabValue}
                  onChange={operations.onHandleTab}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: '#FF6151',
                    },
                  }}>
              <Tab label="팝업정보"
                   sx={{
                     "&.Mui-selected": {
                        color: "#FF6151",
                      },
                   }} />
              {models.posts.length !== 0 && (<Tab label="실시간 현장"
                                                  sx={{
                                                    "&.Mui-selected": {
                                                      color: "#FF6151",
                                                    },
                                                  }}/>)}
            </Tabs>
          </Box>

          <Box role="tabpanel"
               hidden={models.tabValue !== 0}>
            <Grid container
                  direction="column"
            >
              <Grid mt={2}
                    display="flex"
                    columnGap={2}
                    paddingLeft="10px"
                    paddingRight="10px"
                    paddingBottom="10px"
                    sx={{
                      borderBottom: '1px solid #E0E0E0',
                    }}>
                {models.popupStore.isParkingAvailable && (
                  <Box component="img"
                       src={ParkingIcon} />
                )}
                {models.popupStore.isFreeEntrance && (
                  <Box component="img"
                       src={FreeEntranceIcon} />
                )}
              </Grid>

              <Grid mt={3}
                    paddingLeft="10px"
                    paddingRight="10px">
                <Typography fontFamily="Apple SD Gothic Neo"
                            fontSize="16px"
                            lineHeight="22.4px"
                            fontWeight={700}
                            color="#000000">{models.popupStore.descriptionTitle}</Typography>
              </Grid>

              <Grid mt={2}
                    paddingLeft="10px"
                    paddingRight="10px"
                    paddingBottom="15px"
                    sx={{
                      borderBottom: '1px solid #E0E0E0',
                    }}>
                <Typography whiteSpace="pre-wrap"
                            fontFamily="Apple SD Gothic Neo"
                            fontWeight={400}
                            fontSize="14px"
                            color="#000000">
                  {models.popupStore.description}
                </Typography>
              </Grid>
            </Grid>

            <Grid>
              <Box padding="10px">
                <Typography fontFamily="Apple SD Gothic Neo"
                            fontSize="16px"
                            lineHeight="22.4px"
                            fontWeight={700}
                            color="#000000">운영정보</Typography>
                <Accordion className="opening-accordion"
                           square
                           sx={{
                             border: 'none',
                             boxShadow: 'none',
                             padding: '0px',
                             margin: "0px",
                           }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                    sx={{ border: 'none', padding: '0px' }}>
                    <Box display="flex"
                         alignItems="center"
                         columnGap="10px">
                      <Box display="flex">
                        <Box component="img"
                             src={WatchIcon} /> &nbsp;
                        <Typography fontFamily="Apple SD Gothic Neo"
                                    fontSize="14px"
                                    lineHeight="19.2px"
                                    fontWeight={700}
                                    color="#FF6151"
                                    sx={{ marginTop: "3px" }}>{models.todayOpening.isOpen ? "영업중" : "휴무"}</Typography>
                      </Box>
                      <Box>
                        <Typography fontFamily={"Sansation Regular"}
                                    fontSize="14px"
                                    color="#000000">
                          {models.opening?.locale('en').format("HH:mm A")} ~ {models.closing?.locale('en').format("hh:mm A")}
                        </Typography>
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={{
                    marginTop: "0",
                    paddingTop: 0,
                    paddingLeft: "20px",
                  }}>
                    <Typography fontFamily="Sansation Regular"
                                fontSize="12px"
                                color={`${models.today === 1 ? "#FF6151" : "#969696"}`}>
                      월&nbsp;&nbsp;&nbsp;&nbsp;{models.popupStore.openings.monday.isOpen ?
                      `${models.popupStore.openings.monday.opening} ~ ${models.popupStore.openings.monday.closing}`: "휴무"}
                    </Typography>
                    <Typography fontFamily="Sansation Regular"
                                fontSize="12px"
                                color={`${models.today === 2 ? "#FF6151" : "#969696"}`}>
                      화&nbsp;&nbsp;&nbsp;&nbsp;{models.popupStore.openings.monday.isOpen ?
                      `${models.popupStore.openings.tuesday.opening} ~ ${models.popupStore.openings.tuesday.closing}`: "휴무"}
                    </Typography>
                    <Typography fontFamily="Sansation Regular"
                                fontSize="12px"
                                color={`${models.today === 3 ? "#FF6151" : "#969696"}`}>
                      수&nbsp;&nbsp;&nbsp;&nbsp;{models.popupStore.openings.monday.isOpen ?
                      `${models.popupStore.openings.wednesday.opening} ~ ${models.popupStore.openings.wednesday.closing}`: "휴무"}
                    </Typography>
                    <Typography fontFamily="Sansation Regular"
                                fontSize="12px"
                                color={`${models.today === 4 ? "#FF6151" : "#969696"}`}>
                      목&nbsp;&nbsp;&nbsp;&nbsp;{models.popupStore.openings.monday.isOpen ?
                      `${models.popupStore.openings.thursday.opening} ~ ${models.popupStore.openings.thursday.closing}`: "휴무"}
                    </Typography>
                    <Typography fontFamily="Sansation Regular"
                                fontSize="12px"
                                color={`${models.today === 5 ? "#FF6151" : "#969696"}`}>
                      금&nbsp;&nbsp;&nbsp;&nbsp;{models.popupStore.openings.monday.isOpen ?
                      `${models.popupStore.openings.friday.opening} ~ ${models.popupStore.openings.friday.closing}`: "휴무"}
                    </Typography>
                    <Typography fontFamily="Sansation Regular"
                                fontSize="12px"
                                color={`${models.today === 6 ? "#FF6151" : "#969696"}`}>
                      토&nbsp;&nbsp;&nbsp;&nbsp;{models.popupStore.openings.monday.isOpen ?
                      `${models.popupStore.openings.saturday.opening} ~ ${models.popupStore.openings.saturday.closing}`: "휴무"}
                    </Typography>
                    <Typography fontFamily="Sansation Regular"
                                fontSize="12px"
                                color={`${models.today === 0 ? "#FF6151" : "#969696"}`}>
                      일&nbsp;&nbsp;&nbsp;&nbsp;{models.popupStore.openings.monday.isOpen ?
                      `${models.popupStore.openings.sunday.opening} ~ ${models.popupStore.openings.sunday.closing}`: "휴무"}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Grid>

            <Stack paddingLeft="10px"
                   paddingRight="10px"
                   paddingTop="10px"
                   sx={{
                     borderTop: '1px solid #E0E0E0',
                   }}>
              <Grid>
                <Typography fontFamily="Apple SD Gothic Neo"
                            fontSize="16px"
                            lineHeight="22.4px"
                            fontWeight={700}
                            color="#000000">브랜드 홈페이지</Typography>
              </Grid>

              <Grid mt={2}
                    mb={15}
                    display="flex"
                    justifyContent="start"
                    spacing={4}>
                <Button startIcon={<Box component="img" src={OfficialIcon} />}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "8px",
                          backgroundColor: "#F7F7F7",
                          marginRight: "10px",
                        }}>
                  <Typography fontFamily="Apple SD Gothic Neo"
                              fontSize="12px"
                              fontWeight={400}
                              color="#000000">공식 홈페이지</Typography>
                </Button>
                <Button startIcon={<Box component="img" src={InstagramIcon} />}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "8px",
                          backgroundColor: "#F7F7F7"
                        }}>
                  <Typography fontFamily="Apple SD Gothic Neo"
                              fontSize="12px"
                              fontWeight={400}
                              color="#000000">인스타그램</Typography>
                </Button>
              </Grid>
            </Stack>

            <Box position="absolute"
                 bottom={0}
                 paddingLeft="10px"
                 paddingRight="10px"
                 paddingTop="15px"
                 className="dynamic-width"
                 zIndex={2}
                 sx={{
                   backgroundColor: "#FFFFFF",
                   boxShadow: "0 -3px 8px 0 rgba(0, 0, 0, 0.05)",
                 }}
            >
              <Grid container
                    spacing={2}>
                <Grid item
                      xs={7}>
                  <Button variant="contained"
                          startIcon={<Box component="img" src={SugarIcon} />}
                          onClick={() => operations.navigate('/introduces')}
                          sx={{
                            width: "100%",
                            height: "50px",
                            backgroundColor: "#FF6151",

                            "&:hover": {
                              backgroundColor: "#FF6151",
                            },
                          }}
                  >현장 사진 올리고 설탕 받기</Button>
                </Grid>
                <Grid item
                      xs={5}>
                  <Button variant="contained"
                          startIcon={<Box component="img" src={ShareIcon} />}
                          onClick={() => operations.navigate('/introduces')}
                          sx={{
                            width: "100%",
                            height: "50px",
                            backgroundColor: "#303030",

                            "&:hover": {
                              backgroundColor: "#303030",
                            },
                          }}
                  >공유하기</Button></Grid>
              </Grid>
              <Button></Button>
            </Box>
          </Box>

          <Box role="tabpanel"
               hidden={models.tabValue !== 1}>
            <Stack paddingLeft="10px"
                   paddingRight="10px"
                   paddingTop="20px">
              <Typography fontFamily="Apple SD Gothic Neo"
                          fontSize="18px"
                          fontWeight={700}
                          lineHeight="21.6px"
                          color="#000000">🔥 실시간 현장보기 </Typography>

              <Grid container
                    className="moment">
                {models.posts.map((post, index) => (
                  <Grid item
                        className="moment-content">
                    <Box className="moment-content-img" key={post.id}>
                      <div className="moment-content-img-time">{`
                    ${calculateTimeAgo(dayjs(post.createdAt).add(9, 'hours').format("YYYY-MM-DD HH:mm:ss"))}`}</div>
                      <img
                        onClick={() => operations.onClickPost(post.id)}
                        src={post.images[0]}
                        alt=""
                        className="moment-content-img-src"
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Stack>
          </Box>
        </Stack>
      </Stack>

      <DrawerRoot open={models.bottomSheetOpen}
                  hideDrawer={() => operations.setBottomSheetOpen(false)} />
    </div>
  );
}