import React, { useEffect, useState } from 'react';
import { FetchPopupStoreRankings, PopupStoreRanking } from '../../services/popup-stores/fetchRankings';
import Grid from '@mui/material/Grid';

import { Outlet, useNavigate, useOutlet } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import MapLayoutWithHeader from '../../layouts/MapLayoutWithHeader';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ChevronRightBlack from 'assets/images/chevron_right_black.svg';
import Ranking1 from 'assets/images/ranking1.svg';
import Ranking2 from 'assets/images/ranking2.svg';
import Ranking3 from 'assets/images/ranking3.svg';
import { FetchPopupStores } from '../../services/popup-stores/fetchPopupStores';
import ToOpenPopupStore from './components/ToOpenPopupStore';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import dayjs from "dayjs";
import "dayjs/locale/ko";
import Sugar from 'assets/images/ic_sugar.svg';
import ChevronDown from 'assets/icons/chevron_down.svg';
import { ButtonBase } from '@mui/material';
import { PopupStore } from '../../services/presentation/PopupStore';
dayjs.locale("ko");

const usePopupStores = () => {
  const navigate = useNavigate();
  const [popupStores, setPopupStores] = useState<PopupStore[]>([]);
  const [toOpenPopupStores, setToOpenPopupStores] = useState<PopupStore[]>([]);
  const [popupStoreRankings, setPopupStoreRankings] = useState<PopupStoreRanking[]>([]);
  const [order, setOrder] = useState('latest');
  const rankingImages = [Ranking1, Ranking2, Ranking3];

  useEffect(() => {
    async function fetchPopupStoreRankings() {
      const popupStores = await FetchPopupStoreRankings();
      setPopupStoreRankings(popupStores.slice(0, 3));
    }

    async function fetchToOpenPopupStores() {
      const popupStores = await FetchPopupStores(true);
      setToOpenPopupStores(popupStores);
    }

    async function fetchPopupStores() {
      const popupStores = await FetchPopupStores();
      setPopupStores(popupStores.filter((popupStore: PopupStore) => dayjs().format('YYYY-MM-DD') >= dayjs(popupStore.startDate).format('YYYY-MM-DD')));
    }

    fetchPopupStoreRankings();
    fetchToOpenPopupStores();
    fetchPopupStores();
  }, []);

  return {
    models: {
      toOpenPopupStores,
      popupStoreRankings,
      popupStores,
      rankingImages,
      order,
    },
    operations: {
      navigate,
      setOrder,
    },
  };
}

const PopupStoresPage = () => {
  const outlet = useOutlet();
  return outlet ? <Outlet /> : <PopupStores />;
};

const PopupStores = () => {
  const { models, operations } = usePopupStores();

  return (
    <MapLayoutWithHeader title="팝업 둘러보기"
                         onClickBackButton={() => operations.navigate('/')}>
      <Grid container
            justifyContent="space-between"
            alignItems="baseline">
        <Grid item>
          <Typography fontFamily="Apple SD Gothic Neo"
                      fontSize="18px"
                      fontWeight={700}
                      lineHeight="21.6px"
                      color="#000000">오픈예정 팝업 💓</Typography>
        </Grid>
        <Grid item>
          <Button onClick={() => operations.navigate('/popup-stores/to-opens')}>
            <Typography fontFamily="Apple SD Gothic Neo"
                        fontSize="12px"
                        fontWeight={600}
                        color="#969696"
                        lineHeight="14.4px">전체보기</Typography>
            <Box component="img"
                 src={ChevronRightBlack} />
          </Button>
        </Grid>
      </Grid>

      <Grid container
            mt="16px"
            zIndex={10}>
        <Grid item
              sx={{
                width: '100%',
                overflowX: 'scroll',
              }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
          }}>
            {models.toOpenPopupStores.map((popupStore: PopupStore) => {
              const { id, name, thumbnailImages, isPreReservation, address } = popupStore;
              const addressSplit = address.split(' ');

              return (
                <ButtonBase key={id}
                            onClick={() => operations.navigate(`/popup-stores/${id}`)}>
                  <ToOpenPopupStore background={thumbnailImages[0]}
                                    width="216px">
                    <Box justifyContent="space-between"
                         flexDirection="column"
                         display="flex"
                         position="absolute"
                         zIndex={30}
                         width="100%"
                         height="100%">
                      <Box alignSelf="flex-end"
                           mt={1}
                           mr={1}
                           sx={{
                             opacity: isPreReservation ? 1 : 0,
                           }}>
                        <Button sx={{
                          backgroundColor: '#FFFFFF',
                          borderRadius: '18px',
                          paddingBottom: '3px',
                          "&:hover": {
                            backgroundColor: '#FFFFFF',
                          },
                        }}>
                          <Typography fontFamily="Apple SD Gothic Neo"
                                      fontSize="9px"
                                      fontWeight={400}
                                      color="#000000"
                                      lineHeight="10.8px">🎟️ 사전예약</Typography>
                        </Button>
                      </Box>

                      <Box ml={1}
                           mb={1}
                           display="flex"
                          flexDirection="column"
                          justifyContent="start"
                          alignItems="start">
                        <Typography fontFamily="Apple SD Gothic Neo"
                                    fontSize="14px"
                                    fontWeight={600}
                                    lineHeight="16.8px"
                                    color="#FFFFFF"
                                    mb={1}
                                    ml={1}
                        >{name}</Typography>
                        <Typography fontFamily="Apple SD Gothic Neo"
                                    fontSize="12px"
                                    fontWeight={400}
                                    color="#FFFFFF"
                        ><LocationOnIcon fontSize="inherit" />{`${addressSplit[0]} ${addressSplit[1]}`}</Typography>
                      </Box>
                    </Box>
                  </ToOpenPopupStore>
                </ButtonBase>

              );
            })}
          </Box>

        </Grid>
      </Grid>

      <Grid container
            mt={4}
            zIndex={10}
            bgcolor={'#FFFFFF'}>
        <Grid item>
          <Typography fontFamily="Apple SD Gothic Neo"
                      fontSize="18px"
                      fontWeight={700}
                      lineHeight="21.6px"
                      color="#000000">실시간 팝업 랭킹 🔥</Typography>
        </Grid>
      </Grid>

      <Grid container
            mt="20px"
            zIndex={10}
            bgcolor={'#FFFFFF'}>
        {models.popupStoreRankings.length > 0 && models.popupStoreRankings.map((popupStoreRanking: PopupStoreRanking, index) => {
          const { isPreReservation, isAssociated, viewCount, startDate, endDate } = popupStoreRanking;
          const ranking = models.rankingImages[index];
          const addressSplit = popupStoreRanking.address.split(' ');

          return (
            <Grid key={popupStoreRanking.id}
                  container
                  display="flex"
                  justifyContent="start">
              <Grid item
                    sx={{
                      width: '25px',
                      height: '30px',
                    }}>
                <ButtonBase onClick={() => operations.navigate(`/popup-stores/${popupStoreRanking.id}`)}>
                  <Box component="img"
                       src={ranking}
                  />
                </ButtonBase>
              </Grid>
              <Grid item
                    mb={1}
                    sx={{
                      marginLeft: '10px',
                      marginRight: '10px',
                      width: '140px',
                      height: '140px',
                    }}>
                <ButtonBase onClick={() => operations.navigate(`/popup-stores/${popupStoreRanking.id}`)}>
                  <Box component="img"
                       borderRadius="8px"
                       src={popupStoreRanking.thumbnailImages[0]}
                       width="140px"
                       height="140px"/>
                </ButtonBase>
              </Grid>
              <Grid item
                    whiteSpace="nowrap"
                    sx={{
                      width: 'calc(100% - 185px)',
                    }}>
                <ButtonBase onClick={() => operations.navigate(`/popup-stores/${popupStoreRanking.id}`)}
                            sx={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'start',
                            }}>
                  <Typography fontFamily="Apple SD Gothic Neo"
                              fontSize="14px"
                              fontWeight={600}
                              lineHeight="16.8px"
                              color="#000000"
                              overflow="hidden"
                              whiteSpace="nowrap"
                              textOverflow="ellipsis">{popupStoreRanking.name}</Typography>
                </ButtonBase>
                <Typography fontFamily="Apple SD Gothic Neo"
                            fontSize="12px"
                            fontWeight={400}
                            lineHeight="14.4px"
                            mt={1}
                            color="#6F6F6F">
                  <LocationOnIcon fontSize="inherit" />{addressSplit[0]} {addressSplit[1]}
                </Typography>
                <Typography fontFamily="Apple SD Gothic Neo"
                            fontSize="12px"
                            fontWeight={500}
                            lineHeight="14.4px"
                            mt={1}
                            color="#6F6F6F">{dayjs(startDate).format('MM.DD(dd)')} ~ {dayjs(endDate).format('MM.DD(dd)')}</Typography>
                <Typography fontFamily="Apple SD Gothic Neo"
                            fontSize="12px"
                            fontWeight={700}
                            lineHeight="14.4px"
                            mt={1}
                            color="#000000"><b>🔥{viewCount.toLocaleString('en')}명</b>이 봤어요</Typography>
                <Box mt={1}>
                  {isAssociated && (
                    <Button size="small"
                            sx={{
                              backgroundColor: '#FFFFFF',
                              borderRadius: '18px',
                              border: '0.5px solid #969696',
                              padding: 0,
                              "&:hover": {
                                backgroundColor: '#FFFFFF',
                              },
                              marginRight: '5px',
                            }}>
                      <Box component="img"
                           src={Sugar} /> &nbsp;
                      <Typography fontFamily="Apple SD Gothic Neo"
                                  fontSize="9px"
                                  fontWeight={700}
                                  color="#000000"
                                  lineHeight="10.8px"
                                  sx={{
                                    paddingTop: '3px',
                                  }}>
                        리워드</Typography>
                    </Button>
                  )}
                  {isPreReservation && (
                    <Button size="small"
                            sx={{
                              backgroundColor: '#FFFFFF',
                              borderRadius: '18px',
                              border: '.5px solid #969696',
                              padding: "6px 5px",
                              "&:hover": {
                                backgroundColor: '#FFFFFF',
                              },
                            }}>
                      <Typography fontFamily="Apple SD Gothic Neo"
                                  fontSize="9px"
                                  fontWeight={700}
                                  color="#000000"
                                  lineHeight="10.8px">🎟️ 예약가능</Typography>
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          );
        })}
      </Grid>

      {models.popupStoreRankings.length > 0 && (
        <Grid container
              sx={{ padding: 0, margin: 0,  }}
              bgcolor={'#FFFFFF'}
              zIndex={10}>
          <Box sx={{
            padding: 0,
            marginTop: '10px',
            marginLeft: '-10px',
            marginRight: '-10px',
            width: 'calc(100% + 30px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderTop: '1px solid #E0E0E0',
            backgroundColor: '#FFFFFF',
          }}>
            <ButtonBase onClick={() => operations.navigate('/popup-stores/rankings')}
                        sx={{
                          width: '100%',
                          paddingTop: '20px',
                          paddingBottom: '20px',
                        }}>
              <Typography fontFamily="Apple SD Gothic Neo"
                          fontSize="14px"
                          fontWeight={600}
                          color="#FF6151"
                          lineHeight="14.4px">전체보기 &gt; </Typography>
            </ButtonBase>
          </Box>
        </Grid>
      )}

      <Grid container>
        <Box sx={{
          marginLeft: '-10px',
          marginRight: '-10px',
          height: '12px',
          width: 'calc(100% + 30px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#F8F8F8',
        }}>
          &nbsp;
        </Box>
      </Grid>

      <Grid container
            mt={4}
            mb="20px"
            display="flex"
            justifyContent="space-between"
            alignItems="center">
        <Grid item>
          <Typography fontFamily="Apple SD Gothic Neo"
                      fontSize="18px"
                      fontWeight={700}
                      lineHeight="21.6px"
                      color="#000000">팝업스토어 전체</Typography>
        </Grid>
        <Grid item>
          <select onChange={(e) => operations.setOrder(e.target.value)}
                  style={{
                    fontFamily: 'Apple SD Gothic Neo',
                    fontSize: '11px',
                    fontWeight: 500,
                    color: '#000000',
                    border: '1px solid #969696',
                    borderRadius: '18px',
                    appearance: 'none',
                    padding: '4px 28px 4px 14px',
                    backgroundColor: '#FFFFFF',
                    backgroundImage: `url(${ChevronDown})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'right 8px center',
                  }}>
            <option value="latest">최신순</option>
            <option value="posts">후기순</option>
          </select>
        </Grid>
      </Grid>

      <Grid container
            spacing={2}>
        {models.popupStores.length > 0 &&
          models
            .popupStores
            .sort((a: PopupStore, b: PopupStore) => {
              if (models.order === 'latest') {
                return dayjs(b.startDate).diff(dayjs(a.startDate));
              } else {
                return b.viewCount - a.viewCount;
              }
            })
            .map((popupStore: PopupStore) => {
          const { id, thumbnailImages, name, isAssociated, address } = popupStore;
          const addressSplit = address.split(' ');

          return (
            <Grid item
                  xs={6}>
              <ButtonBase key={id}
                          onClick={() => operations.navigate(`/popup-stores/${id}`)}
                          sx={{
                            marginBottom: '10px',
                            width: '100%',
                          }}>
                <Box sx={{
                  width: '100%',
                }}>
                  <ToOpenPopupStore background={thumbnailImages[0]}
                                    width="inherit"
                  >
                    <Box justifyContent="space-between"
                         flexDirection="column"
                         display="flex"
                         position="absolute"
                         zIndex={100}
                         width="100%"
                         height="100%">
                      <Box alignSelf="flex-end"
                           mt={1}
                           mr={1}
                           sx={{
                             opacity: isAssociated ? 1 : 0,
                           }}>
                        <Button startIcon={<Box component="img" src={Sugar} />}
                                sx={{
                                  backgroundColor: '#FFFFFF',
                                  borderRadius: '18px',
                                  "&:hover": {
                                    backgroundColor: '#FFFFFF',
                                  },
                                }}>
                          <Typography fontFamily="Apple SD Gothic Neo"
                                      fontSize="9px"
                                      fontWeight={700}
                                      color="#000000"
                                      lineHeight="10.8px"
                                      sx={{
                                        paddingTop: '3px',
                                      }}>리워드</Typography>
                        </Button>
                      </Box>
                    </Box>
                  </ToOpenPopupStore>
                </Box>
              </ButtonBase>

              <Typography fontFamily="Apple SD Gothic Neo"
                          fontSize="14px"
                          fontWeight={600}
                          lineHeight="16.8px"
                          color="#000000">{name}</Typography>
              <Typography fontFamily="Apple SD Gothic Neo"
                          fontSize="12px"
                          fontWeight={400}
                          lineHeight="14.4px"
                          mt={1}
                          color="#6F6F6F">
                <LocationOnIcon fontSize="inherit" />{addressSplit[0]} {addressSplit[1]}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </MapLayoutWithHeader>
  );
}

export default PopupStoresPage;
