import { useEffect, useState } from 'react';
import { NavermapsProvider } from 'react-naver-maps';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Loading from 'components/Loading';
import AppleLogo from './AppleLogo';
import Logo from './Logo';
import Map from './components/Map';
import './styles.scss';
import { FetchPopupStoreRankings, PopupStoreRanking } from '../../services/popup-stores/fetchRankings';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import { SearchInput } from './components/SearchInput';
import Grid from '@mui/material/Grid';
import { RankingButton } from './components/RankingButton';
import handshake from 'assets/images/handshake.svg';
import { useNavigate } from 'react-router-dom';
import chevronRight from 'assets/images/chevron_black_right.png';
import earthImage from 'assets/images/earth_img.png';

const useHome = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>('');
  const [popupStoreRankings, setPopupStoreRankings] = useState<PopupStoreRanking[]>([]);

  useEffect(() => {
    async function fetchPopupStoreRankings() {
      const popupStores = await FetchPopupStoreRankings();
      setPopupStoreRankings(popupStores);
    }

    fetchPopupStoreRankings();
  }, []);

  return {
    models: {
      searchValue,
      popupStoreRankings,
    },
    operations: {
      navigate,
      setSearchValue,
    },
  };
}

function Home() {
  const { models, operations } = useHome();

  return (
    <Box className="root">
      <Box className="root-img">
        <Box className="root-img-box"
             component="img"
             src={earthImage} />
      </Box>
      <Box className="left-content">
        <Box className="typhography-line-1">
          <Typography fontSize="32px"
                      fontWeight={500}
                      lineHeight="41.6px"
                      fontFamily="KBODiaGothic">실시간 </Typography>
          <Typography fontSize="32px"
                      fontWeight={500}
                      lineHeight="41.6px"
                      color="#FF6151"
                      fontFamily="KBODiaGothic">&nbsp;팝업/핫플&nbsp;</Typography>
          <Typography fontSize="32px"
                      fontWeight={500}
                      lineHeight="41.6px"
                      fontFamily="KBODiaGothic">라이브 지도</Typography>
        </Box>
        <Box className="logo">
          <Logo />
        </Box>

        <Box mt="32px"
             sx={{
               height: '58px',
             }}>
          <SearchInput placeholder="궁금한 팝업스토어를 검색해보세요."
                       onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            operations.navigate(`/search?keyword=${models.searchValue}`);
                          }
                       }}
                       onChange={(e) => operations.setSearchValue(e.target.value)}
                       InputProps={{
                         startAdornment: (<SearchIcon sx={{
                           marginRight: '10px',
                         }}/>),
                       }} />
        </Box>

        {models.popupStoreRankings.length > 0 && (
          <Grid container
                spacing="10px"
                mt="32px"
                mx={0}
                columnGap="10px"
                rowGap="5px"
                sx={{ width: '353px' }} >
            {models.popupStoreRankings.slice(0, 5).map((popupStoreRanking) => (
              <RankingButton key={popupStoreRanking.id}
                             onClick={() => operations.navigate(`/popup-stores/${popupStoreRanking.id}`)}>{popupStoreRanking.name}</RankingButton>
            ))}
          </Grid>
        )}

        <Box mt="36px"
             sx={{
                width: '366px',
                height: '116px',
                borderRadius: '8px',
                backgroundImage: `url("https://d2gn65wu4j7pv2.cloudfront.net/imageOther/associate_background.png")`,
                backgroundSize: 'cover',
                wordBreak: 'keep-all',
                padding: '12px 16px',
              }}>
          <Grid container>
            <Grid item
                  xs={7}>
              <Typography fontSize="16px"
                          fontWeight={500}
                          lineHeight="25px"
                          color="#FF6151"
                          fontFamily="KBODiaGothic">프레젬은 언제나 활짝 열려있습니다!</Typography>
              <Button onClick={() => window.open('https://forms.gle/4mAfiPx9g57xz6dr5')}
                      sx={{
                        marginTop: '10px',
                        borderRadius: '24px',
                        padding: '5px 16px',
                        backgroundColor: '#FFFFFF',
                        color: 'black',
                        fontFamily: 'Apple SD Gothic Neo',
                        border: '1px solid #d5d5d5',
                      }}>광고·협업 문의하기 <Box component="img"
                                         src={chevronRight}
                                         sx={{
                                           marginLeft: '5px',
                                           paddingBottom: '3px',
                                         }} /></Button>
            </Grid>
            <Grid item
                  xs={5}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }} >
              <Box className="icon" component={'img'} src={handshake} />
            </Grid>
          </Grid>
        </Box>

        <Box className="download">
          <Button className="button"
                  target="_blank"
                  href="https://apps.apple.com/kr/app/prezem-%ED%94%84%EB%A0%88%EC%A0%AC/id6446088313"
                  variant="contained"
                  startIcon={<AppleLogo />} >
            App Store
          </Button>
        </Box>
      </Box>

      <Box className="right-content">
        <Box className='map'>
          {process.env.REACT_APP_CLIENT_ID && (
            <NavermapsProvider ncpClientId={process.env.REACT_APP_CLIENT_ID}>
              <Loading />
              <Map />
            </NavermapsProvider>
          )}
        </Box>
      </Box>
    </Box>
  );
}



export default Home;
